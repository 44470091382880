import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../context/AuthProvider';
import { getQuerystringValue, getStorage, setStorage } from '../helpers/general';
import Button from '../components/atoms/Button/Button';
import Container from '../components/atoms/Container/Container';
import Dialog from '../components/atoms/Dialog/Dialog';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import { Center, Image, useToast } from '@chakra-ui/react';
import { Link, navigate } from 'gatsby';
import * as pcStyles from './login.module.css';
import * as mpsStyles from './login.mps.module.css';
import { storeStyle, isMadPawsStore } from '../common/util';

const styles = storeStyle({ mpsStyles, pcStyles });
const LoginPage = () => {
  const toast = useToast();
  const auth = useContext(AuthContext);
  const handleLogin = auth && auth.login;
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const userChecked = auth && auth.state.userChecked;
  const [loading, setLoading] = useState(false);
  const [pageLoaded, setLoaded] = useState(false);
  let redirectTo = '/account';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);
  const afterAuthCalls = getStorage('_afterAuth');
  let afterAuthCallsObject = {};

  const isMobile = () => {
    return window.innerWidth < 800;
  };

  if (afterAuthCalls) {
    afterAuthCallsObject = JSON.parse(afterAuthCalls);
  }

  if (typeof window !== 'undefined' && getQuerystringValue('redirectTo')) {
    redirectTo = getQuerystringValue('redirectTo');
    setStorage('forcedPage', `${window.location.origin}${redirectTo}`, true);
  }

  useEffect(() => {
    if (!pageLoaded && isLoggedIn && typeof window !== 'undefined') {
      window.location = '/account/';
    }
    if (userChecked) {
      setLoaded(true);
    }
  }, [isLoggedIn, pageLoaded, userChecked]);
  const attemptLogin = e => {
    e.preventDefault();
    setLoading(true);
    handleLogin(email, password, remember)
      .then(() => {
        // console.log(response);
      })
      .catch(error => {
        toast({
          title: 'Login failed',
          description:
            error?.response?.message?.message ??
            'Please check your email and password and try again.',
          status: 'error',
          position: 'top-right',
          colorScheme: 'secondary'
        });
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearDialog = () => {
    setDialogMessage(false);
  };

  const onChangeCheckbox = e => {
    return setRemember(e.target.checked);
  };

  const handleCancel = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return false;
    }
    setDialogMessage(false);
  };

  if (!isLoggedIn && userChecked) {
    return (
      <Layout className={styles.root}>
        <Seo title="Pet Chemist Online - Sign in" showSiteName={false} />
        <Container size={'large'}>
          <div className={`grid grid-50 ${styles.loginContainer}`}>
            <div className={`${styles.loginForm} center`}>
              <h1 className="h6-login">{isMadPawsStore() ? 'Log in' : 'Login'}</h1>
              {'action' in afterAuthCallsObject &&
                afterAuthCallsObject.action === 'saveWishlist' && (
                  <div className={styles.notice}>Login or Create Account for wishlists</div>
                )}
              <form onSubmit={e => attemptLogin(e)}>
                <div
                  className={`inputs ${
                    'action' in afterAuthCallsObject &&
                    afterAuthCallsObject.action === 'saveWishlist'
                      ? 'wishlist'
                      : ''
                  }`}
                >
                  <div
                    className="formField"
                    style={{
                      marginBottom: isMadPawsStore() ? '16px' : ''
                    }}
                  >
                    <label className={styles.formFieldLabel} htmlFor="email">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className={styles.input}
                      name="email"
                      placeholder="Email Address"
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                  <div
                    className="formField"
                    style={{
                      marginBottom: isMadPawsStore() ? '16px' : ''
                    }}
                  >
                    <label className={styles.formFieldLabel} htmlFor="password">
                      Password
                    </label>
                    <input
                      type="password"
                      className={styles.input}
                      name="password"
                      placeholder="Password"
                      onChange={e => setPassword(e.target.value)}
                    />
                  </div>
                  <div className={`${styles.formActions} formField`}>
                    <div className={styles.formActionsOp}>
                      <div className={styles.rememberMe}>
                        <input
                          type="checkbox"
                          className={styles.formCheckbox}
                          id="remember_me"
                          name="remember_me"
                          checked={remember}
                          onChange={e => onChangeCheckbox(e)}
                          value="Remember me"
                        />
                        <label className={styles.formLabel} htmlFor="remember_me">
                          Remember me
                        </label>
                      </div>
                      <Link className={styles.forgot} to="/forgotpassword/">
                        {isMadPawsStore() ? 'Forgot password' : 'Forgot password?'}
                      </Link>
                    </div>
                  </div>
                </div>
                <Button
                  disabled={loading}
                  loading={loading}
                  level="tertiary"
                  lo
                  type="buttonSubmit"
                >
                  {isMadPawsStore() ? 'Log in' : 'Login'}
                </Button>
                <Dialog
                  open={dialogMessage ? true : false}
                  title="Login unsuccessful"
                  size="sm"
                  hideBtnCancel
                  disableBackdropClick
                  onOk={() => clearDialog()}
                  onClose={(event, reason) => handleCancel(event, reason)}
                >
                  {dialogMessage}
                </Dialog>
              </form>
            </div>

            <div className={styles.createAccount}>
              <h1 className="h6-login new-customer-text">New customer?</h1>
              <p className="subtitle">Create an account with us and you'll be able to:</p>
              <ul className="bullets">
                <li>Check out quickly every time</li>
                <li>Track your orders</li>
                <li>Manage any subscriptions</li>
                <li>Receive weekly discounts and coupons</li>
              </ul>
              <Button onClick={() => navigate('/signup/')} level="tertiary" type="button">
                Create account
              </Button>
            </div>
          </div>
          <Center>
            <Image
              m={0}
              style={{
                width: isMadPawsStore() ? (isMobile() ? '60%' : '30%') : 'initial',
                height: isMadPawsStore() ? (isMobile() ? '60%' : '30%') : 'initial'
              }}
              mt={4}
              src={isMadPawsStore() ? '/login_mps.png' : '/login.png'}
              alt="login"
            />
          </Center>
        </Container>
      </Layout>
    );
  } else {
    return null;
  }
};

export default LoginPage;
